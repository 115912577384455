<script setup>
import {defineProps, ref} from 'vue';

const props = defineProps({
  disableSearch: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits(['search'])

const term = ref('');
function search(e) {
  e.preventDefault()
  emit('search', term.value);
  if (e.type === 'click') term.value = '';
}

function throwError() {
  console.log(fooooooo);
}
</script>

<template>
  <nav class="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="/" style="padding-top: 0; padding-bottom: 0;">
        <img src="/scout-js.png" class="logo" alt="Logo" style="height: 39px">
      </a>
<!--      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">-->
<!--        <span class="navbar-toggler-icon"></span>-->
<!--      </button>-->
      <div v-if="!props.disableSearch" class="collapse navbar-collapse me-auto">
        <form class="d-flex" @submit.stop="search">
          <input class="form-control me-sm-2" type="search" v-model="term" @input="search" placeholder="Search">
          <button class="btn btn-secondary my-2 my-sm-0" type="button" @click="search">Search</button>
        </form>
      </div>
      <div class="d-flex">
        <button class="btn btn-secondary my-2 my-sm-0" type="button" @click="throwError">Throw Error</button>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.mascot {
  height: 100px !important;
  position: absolute;
  left: -36px;
  top: 15px;
  pointer-events: none;
  transform: scale(0.65);
  transition: all 300ms ease;
  opacity: 0;
}
.logo:hover ~ .mascot {
  transform: translateX(40px) scale(1);
  opacity: 1;
}
</style>