// stores/index.js
import { defineStore } from 'pinia';
import { ref } from 'vue';

const server_host = location.hostname === 'localhost' ? 'http://localhost:5001' : '';

export const useAuthStore = defineStore('auth', () => {
    const isAuthenticated = ref(false);
    const username = ref('');
    const password = ref('');
    const projects = ref([]); // New state property to hold project names
    const loadingProjects = ref(false); // Loading state for projects
    const errors = ref([]); // New state property to hold project names
    const loadingErrors = ref(false); // Loading state for projects
    const hashes = ref([]);

    // Login action
    async function login(user, pass) {
        const response = await fetch(server_host+'/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Username': user,
                'X-Password': pass,
            },
        });

        if (!response.ok) throw new Error('Login failed');

        // Store username and password in sessionStorage
        sessionStorage.setItem('username', user);
        sessionStorage.setItem('password', pass);
        isAuthenticated.value = true;
    }

    // Function to automatically sign in if credentials are stored
    function autoSignIn() {
        const storedUser = sessionStorage.getItem('username');
        const storedPass = sessionStorage.getItem('password');

        if (storedUser && storedPass) {
            // Call login function with stored credentials
            login(storedUser, storedPass).catch(error => console.error(error));
            isAuthenticated.value = true;
            return true;
        }
    }

    // Logout action
    function logout() {
        isAuthenticated.value = false;
        username.value = '';
        password.value = '';
    }

    // Action to fetch unique project names
    async function fetchProjects() {
        loadingProjects.value = true; // Set loading state to true
        try {
            const storedUser = sessionStorage.getItem('username');
            const storedPass = sessionStorage.getItem('password');

            const response = await fetch(server_host+'/projects', {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Username': username.value || storedUser,
                    'X-Password': password.value || storedPass,
                }
            });
            if (!response.ok) throw new Error('Failed to fetch projects');

            projects.value = await response.json(); // Store fetched projects in the state

            return projects.value;
        } catch (error) {
            console.error(error);
        } finally {
            loadingProjects.value = false; // Set loading state to false after request
        }
    }

    async function fetchErrors(projectName) {
        loadingErrors.value = true;
        try {
            const storedUser = sessionStorage.getItem('username');
            const storedPass = sessionStorage.getItem('password');

            const response = await fetch(server_host+`/errors?project=${encodeURIComponent(projectName)}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Username': username.value || storedUser,
                    'X-Password': password.value || storedPass,
                }
            });
            if (!response.ok) throw new Error('Failed to fetch errors');

            errors.value = await response.json(); // Store fetched errors in the state

            return errors.value;
        } catch (error) {
            console.error(error);
        } finally {
            loadingErrors.value = false; // Set loading state to false after request
        }
    }

    // Action to fetch hashes from the server
    const fetchHashes = async () => {
        try {
            const storedUser = sessionStorage.getItem('username');
            const storedPass = sessionStorage.getItem('password');

            const response = await fetch(server_host+'/hashes', {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Username': username.value || storedUser,
                    'X-Password': password.value || storedPass,
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            hashes.value = await response.json() || [];
            hashes.value = hashes.value.filter(x => ({ hash: x.hash, timestamp: x.timestamp }));
            return hashes.value;
        } catch (error) {
            console.error('Error fetching hashes:', error);
        }
    };

    // Action to save an array of hashes to the server
    const saveHashes = async (hashArray) => {
        try {
            const storedUser = sessionStorage.getItem('username');
            const storedPass = sessionStorage.getItem('password');

            const response = await fetch(server_host+'/hashes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Username': username.value || storedUser,
                    'X-Password': password.value || storedPass,
                },
                body: JSON.stringify(hashArray),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error saving hashes:', error);
        }
    };

    return {
        isAuthenticated,
        username,
        password,
        projects,
        loadingProjects,
        errors,
        loadingErrors,
        autoSignIn,
        login,
        logout,
        fetchProjects,
        fetchErrors,
        fetchHashes,
        saveHashes,
    };
});
