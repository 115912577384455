<script setup>
import { ref, onMounted, computed } from 'vue';
import { RouterLink } from 'vue-router';
import BarChart from '../components/BarChart.vue';
import { getRelativeTime } from '@/utils.js';

import Navbar from '../components/navbar.vue';

import { useAuthStore } from '../stores/index'

const authStore = useAuthStore();

const projects = ref([]);

onMounted(async () => {
  projects.value = await authStore.fetchProjects();
});

const filteredProjects = computed(() => {
  if (!term.value) return projects.value;
  return (projects.value || []).filter(x => x.project.toLowerCase().includes((term.value || '').toLowerCase().trim()))
})

const term = ref('');

function navbarSearchAction(str) {
  term.value = str;
}
</script>

<template>

  <Navbar @search="navbarSearchAction" />

  <div class="container">

    <div class="row pt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <RouterLink to="/">Home</RouterLink>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Projects
          </li>
        </ol>
      </nav>
    </div>

    <div class="row pt-2">

      <div class="col-xs-12 text-center mt-5" v-if="!projects.length">
        <div
            class="spinner-grow"
            style="width: 3rem; height: 3rem"
            role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div
          v-for="project in filteredProjects"
          :key="project.project"
          class="col-xs-12 col-md-6 col-xl-4"
          style="overflow: initial"
          v-else
      >
        <RouterLink :to="`/projects/${ project.project }/errors`" class="card mb-3">
          <h3 class="card-header">
            <div class="float-end">&rarr;</div>
            {{ project.project }}
          </h3>
          <div class="card-body pb-0">
            <BarChart :data="project.hourlyStats" resolution="hourly" :maxItems="24" style="height: 80px" />
          </div>
          <div class="card-footer text-muted" v-if="project.lastErrorTimestamp || project.errorCount">
            <span v-if="project.lastErrorTimestamp">
              Last Error {{ getRelativeTime(+ new Date(project.lastErrorTimestamp)) }}
            </span>
            <br v-if="project.lastErrorTimestamp && project.errorCount">
            <span v-if="project.errorCount">{{ project.errorCount }} event(s) today</span>
          </div>
          <div class="card-footer text-muted" v-else>
            🥳 No data today
            <br>&nbsp;
          </div>
        </RouterLink>
      </div>

    </div>

    <br><br><br>
  </div>

</template>

<style>
a.card {
  /* transition: all 200ms ease; */
  cursor: pointer;
}
a.card:hover {
  /* Breaks tooltip */
  /* transform: translateY(-3px); */
}
tbody tr {
  cursor: pointer;
}
tbody h4 {
  /*color: #94a8e7;*/
}
pre {
  margin-bottom: 0;
  border-radius: 4px;
  z-index: 9;
}
.highlighted {
  background: #f6f6f6;
}
.hljs {
  background: transparent;
}
.highlight {
  position: absolute;
  top: calc(1em + (14px * 3) - 4px);
  height: calc(14px + 10px);
  background: #94a8e733;
  right: 0;
  left: 0;
}
</style>
