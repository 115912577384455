<template>
  <div class="chart-container">
    <div ref="chart" class="chart"></div>
    <div ref="tooltip" class="tooltip"></div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, watch, defineProps } from 'vue';

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  resolution: {
    type: String,
    default: 'daily',
  },
  maxItems: {
    type: Number,
    default: null, // Default to null if not specified
  },
});

const chart = ref(null);
const tooltip = ref(null);

function generateHourlyTimeSeries(maxItems) {
  const timeseries = [];
  const now = new Date();

  // Reset minutes and seconds to start at an even hour
  now.setMinutes(0, 0, 0);

  for (let i = 0; i < maxItems; i++) {
    // Calculate date and time string for each item, moving backwards by hours
    const date = new Date(now.getTime() - i * 60 * 60 * 1000);
    const formattedDate = date.toISOString().replace('T', ' ').slice(0, 16) + ':00';

    // Push item to timeseries array
    timeseries.unshift({
      date: formattedDate,
      value: 0
    });
  }

  return timeseries;
}

function generateDailyTimeSeries(maxItems) {
  const timeseries = [];
  const now = new Date();

  for (let i = 0; i < maxItems; i++) {
    // Calculate date string for each item, moving backwards by days
    const date = new Date(now.getTime() - i * 24 * 60 * 60 * 1000);
    const formattedDate = date.toISOString().split('T')[0]; // Formats as YYYY-MM-DD

    // Push item to timeseries array
    timeseries.unshift({
      date: formattedDate,
      value: 0
    });
  }

  return timeseries;
}

function padToCurrent(result, resolution) {
  // Get current UTC date or hour as a Date object
  const currentDate = new Date();

  // Get the last date in the array as a Date object
  let lastDate = new Date(result[result.length - 1].date);

  // While the last date in `result` is before `currentDate`, keep adding placeholders
  while (lastDate < currentDate) {
    // Increment lastDate by 1 day or 1 hour based on `resolution`
    const nextDate = resolution === 'daily'
        ? new Date(lastDate.setUTCDate(lastDate.getUTCDate() + 1)) // add 1 day
        : new Date(lastDate.setUTCHours(lastDate.getUTCHours() + 1)); // add 1 hour

    // Format the new date as a string
    const nextDateString = resolution === 'daily'
        ? nextDate.toISOString().split('T')[0] // 'YYYY-MM-DD'
        : nextDate.toISOString().slice(0, 13) + ':00:00'; // 'YYYY-MM-DD HH:00:00'

    // Add the new date with a value of 0
    result.push({ date: nextDateString, value: 0 });

    // Update `lastDate` to the new `nextDate` (as a Date object)
    lastDate = new Date(nextDate);
  }

  return result;
}

function preprocessData(data, maxItems) {
  if (!data || !Array.isArray(data) || !data[0]) {
    if (props.resolution === 'daily') {
      return generateDailyTimeSeries(maxItems);
    } else if (props.resolution === 'hourly') {
      return generateHourlyTimeSeries(maxItems);
    } else {
      return data;
    }
  }

  const isHourly = (data[0].date || '').includes(":") || props.resolution === 'hourly';
  const result = [];

  // Create a date map and determine the min/max dates
  const startDate = new Date(Math.min(...data.map(item => new Date(item.date).getTime())));
  const endDate = new Date(Math.max(...data.map(item => new Date(item.date).getTime())));
  const dateMap = new Map(data.map(item => [item.date, item.value]));

  // Calculate how many entries we need to fill in
  const totalEntries = maxItems || (isHourly ? Math.ceil((endDate - startDate) / (1000 * 60 * 60)) + 1 : Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1);

  // Adjust startDate to go back enough entries to cover maxItems
  if (isHourly) {
    let endDateString = (new Date).toISOString().slice(0, 19).replace('T', ' ').split(':')[0] + ':00';

    startDate.setHours(startDate.getHours() - (totalEntries - 1));
    for (let dt = new Date(startDate); dt <= new Date(endDateString + 'Z'); dt.setHours(dt.getHours() + 1)) {
      const timestamp = dt.toISOString().slice(0, 16).replace('T', ' ');
      result.push({
        date: dt.toISOString().slice(0, 16).replace('T', ' '), // Format as "YYYY-MM-DD HH:mm:ss"
        value: dateMap.get(timestamp) || 0
      });
    }
  } else {
    startDate.setDate(startDate.getDate() - (totalEntries - 1));
    for (let dt = new Date(startDate); dt <= endDate; dt.setDate(dt.getDate() + 1)) {
      const timestamp = dt.toISOString().slice(0, 10);
      result.push({
        date: dt.toISOString().slice(0, 10), // Format as "YYYY-MM-DD"
        value: dateMap.get(timestamp) || 0
      });
    }
  }

  if (!isHourly) padToCurrent(result, props.resolution);

  // If result length exceeds maxItems, truncate it
  if (maxItems && result.length > maxItems) {
    return result.slice(-maxItems); // Return only the last maxItems
  }

  return result;
}

function convertToLocalTime(stats) {
  // return stats;
  if (!Array.isArray(stats)) return stats;
  return stats.map(stat => {
    // Check if the date string includes a time component
    if (stat.date.includes(':')) {
      // Create a new Date object from the UTC date string
      const utcDate = new Date(stat.date + 'Z'); // Append 'Z' to indicate UTC

      // Convert to local time by creating a new Date object from the UTC timestamp
      const localDate = new Date(utcDate.getTime()); // - (utcDate.getTimezoneOffset() * 60000)

      // Get local time components
      const year = localDate.getFullYear();
      const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(localDate.getDate()).padStart(2, '0');
      const hours = String(localDate.getHours()).padStart(2, '0');

      // Format the date string to `yyyy-mm-dd hh:00:00`
      const localDateString = `${year}-${month}-${day} ${hours}:00:00`;

      return {
        ...stat, // Keep the original value
        date: localDateString // Update date to local time
      };
    }
    // If it's a daily timestamp, return the stat as is
    return stat;
  });
}

function createBarChart(data, width, height) {
  if (!width || !height) return;
  // Clear previous chart
  while (chart.value.firstChild) {
    chart.value.removeChild(chart.value.firstChild);
  }

  // Create the SVG element
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", width);
  svg.setAttribute("height", height);
  chart.value.appendChild(svg);

  // Set scales
  const xScale = width / data.length;
  const maxValue = Math.max(...data.map(d => d.value));
  const yScale = height / maxValue;
  const gap = (xScale < 15) ? 2 : 5;

  // Create empty spaces as light grey bars
  const totalBars = Math.ceil(width / xScale);

  for (let i = 0; i < totalBars; i++) {
    const emptyRect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    emptyRect.setAttribute("class", "empty-bar");
    emptyRect.setAttribute("x", i * xScale);
    emptyRect.setAttribute("y", 0);
    emptyRect.setAttribute("rx", gap);
    emptyRect.setAttribute("ry", gap);
    emptyRect.setAttribute("width", xScale - gap); // Add some spacing
    emptyRect.setAttribute("height", height);

    // Tooltip event listeners
    emptyRect.addEventListener("mouseenter", () => {
      tooltip.value.style.visibility = 'visible';
      tooltip.value.innerHTML = `<strong>${data[i]?.date || "N/A"}:</strong> ${data[i]?.value || 0} error(s)`;
    });
    emptyRect.addEventListener("mousemove", (event) => {
      const chartRect = chart.value.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const tooltipWidth = document.querySelector('.chart-container .tooltip').clientWidth;
      let x = event.clientX - chartRect.left; // Adjust for parent element
      if (windowWidth - event.clientX <= tooltipWidth) x -= (tooltipWidth + 15);
      const y = event.clientY - chartRect.top;  // Adjust for parent element
      tooltip.value.style.top = `${y + 10}px`;
      tooltip.value.style.left = `${x + 10}px`;
    });
    emptyRect.addEventListener("mouseleave", () => {
      tooltip.value.style.visibility = 'hidden';
    });

    svg.appendChild(emptyRect);
  }

  // Create bars
  data.forEach((d, i) => {
    const barHeight = d.value * yScale;

    if (!height || !barHeight) return;

    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttribute("class", "bar");
    rect.setAttribute("x", i * xScale);
    rect.setAttribute("y", height - barHeight);
    rect.setAttribute("width", xScale - gap); // Add some spacing
    rect.setAttribute("height", barHeight);

    // Tooltip event listeners
    rect.addEventListener("mouseenter", () => {
      tooltip.value.style.visibility = 'visible';
      tooltip.value.innerHTML = `<strong>${d.date}:</strong> ${d.value} error(s)`;
    });
    rect.addEventListener("mousemove", (event) => {
      const chartRect = chart.value.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const tooltipWidth = document.querySelector('.chart-container .tooltip').clientWidth;
      let x = event.clientX - chartRect.left; // Adjust for parent element
      if (windowWidth - event.clientX <= tooltipWidth) x -= (tooltipWidth + 15);
      const y = event.clientY - chartRect.top;  // Adjust for parent element
      tooltip.value.style.top = `${y + 10}px`;
      tooltip.value.style.left = `${x + 10}px`;
    });
    rect.addEventListener("mouseleave", () => {
      tooltip.value.style.visibility = 'hidden';
    });

    svg.appendChild(rect);
  });
}

let resizeObserver = null;
let resizeTimeout = null;

onMounted(() => {
  resizeObserver = new ResizeObserver(() => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      if (!chart.value) return;

      const width = chart.value.clientWidth;
      const height = chart.value.clientHeight;
      let processedData = preprocessData(props.data, props.maxItems);
      processedData = convertToLocalTime(processedData);
      createBarChart(processedData, width, height);
    }, 100);
  });

  resizeObserver.observe(chart.value);

  // Initial render
  let processedData = preprocessData(props.data, props.maxItems);
  processedData = convertToLocalTime(processedData);
  createBarChart(processedData, chart.value.clientWidth, chart.value.clientHeight);
});

onBeforeUnmount(() => {
  if (resizeObserver) resizeObserver.disconnect();
  if (resizeTimeout) clearTimeout(resizeTimeout);
});
</script>

<style>
.chart, .chart-container {
  width: 100%;
  height: 100%; /* Allow height to be defined by the parent */
  margin-right: -10px;
}

.chart-container {
  position: relative;
}

.chart {
  width: calc(100% + 5px);
}

.chart > svg {
  width: 100%;
  height: auto;
}

.chart .bar {
  fill: steelblue;
  fill: #94a8e7;
  cursor: pointer;
}

.chart .empty-bar {
  fill: #eee;
}

.chart-container .tooltip {
  position: absolute;
  background-color: pink;
  background-color: #d1503b;
  color: #fff;
  border: 3px solid #000;
  padding: 5px;
  opacity: 1;
  visibility: hidden;
  pointer-events: none;
  font-size: 16px;
  z-index: 100;
  border-radius: 5px;
  transition: visibility 0s, opacity 0.2s linear;
  white-space: nowrap;
  display: inline-block;
}

.chart-container .tooltip strong {
  font-weight: 900;
}
</style>
