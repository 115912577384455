import { createApp } from 'vue'
import { createPinia } from 'pinia'
import SmartTable from 'vuejs-smart-table'
import HighlightJSVuePlugin from '@highlightjs/vue-plugin'

import 'highlight.js/styles/stackoverflow-light.css'
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';
import hljsVuePlugin from "@highlightjs/vue-plugin";
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('javascript', javascript);

import App from './App.vue'
import router from './router'

const app = createApp(App)

// Global Vue error handler
app.use({
    install(app) {
        // Error handler
        app.config.errorHandler = async (err, vm, info) => {
            let errorDetails = {
                message: err.message,
                stack: err.stack,
                component: vm?.$options.name || 'Anonymous component',
                type: 'vue-error-handler',
                info
            };

            // console.log('[Sending Vue Error]:', errorDetails);
            if (window.Scout && window.Scout.error) window.Scout.error(errorDetails);
        };

        // Warning handler
        app.config.warnHandler = (msg, vm, trace) => {
            const warningDetails = {
                message: msg,
                component: vm?.$options.name || 'Anonymous component',
                type: 'vue-warn-handler',
                stack: trace,
            };

            // console.log('[Sending Vue Warning]:', warningDetails);
            if (window.Scout && window.Scout.error) window.Scout.error(warningDetails);
        };
    }
});

app.use(createPinia())
app.use(router)

app.use(SmartTable)
app.use(HighlightJSVuePlugin)

app.mount('#app')
