/**
 * Date to human string
 */
const event = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));
const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

var units = {
    year  : 24 * 60 * 60 * 1000 * 365,
    month : 24 * 60 * 60 * 1000 * 365/12,
    day   : 24 * 60 * 60 * 1000,
    hour  : 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000
}

/**
 * Relative dates
 */
export const getRelativeTime = (d1, d2 = new Date()) => {
    try {
        if (!(d1 instanceof Date)) {
            if (d1 === parseInt(d1)) d1 = parseInt(d1);
            d1 = new Date(d1);
        }
    } catch(e){}
    try {
        if (!(d2 instanceof Date)) {
            if (d2 === parseInt(d2)) d2 = parseInt(d2);
            d2 = new Date(d2);
        }
    } catch(e){}
    try {
        var elapsed = d1 - d2;

        // in miliseconds
        var units = {
            year  : 24 * 60 * 60 * 1000 * 365,
            month : 24 * 60 * 60 * 1000 * 365/12,
            day   : 24 * 60 * 60 * 1000,
            hour  : 60 * 60 * 1000,
            minute: 60 * 1000,
            second: 1000
        }

        var rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

        // "Math.abs" accounts for both "past" & "future" scenarios
        for (var u in units)
            if (Math.abs(elapsed) > units[u] || u == 'second')
                return rtf.format(Math.round(elapsed/units[u]), u)
    } catch(e) {
        console.log('could not handle input', d1);
        console.log(e);
        return d1;
    }

}